<template>
  <div class="app">
    <h1>Serveurs</h1>
    
    <div class="serversBloc">
      <div class="servers bloc" v-for="(n, k) in servers" :key="k" :data-online="n.online">
        <div class="title">
          {{ n.path }}
          <span v-if="n.servers[n.active]" :data-alert="n.servers[n.active].alert">{{ n.servers[n.active].ping }}ms</span>
        </div>


        <v-chart style="height: 140px; margin-bottom: 1rem" v-if="n.servers[n.active]" :option="graph(n.servers[n.active].pingHistory)" />
        
        <div class="elem" v-for="(s, k) in n.servers" :key="k" :data-online="k === n.active">
          <div class="line">
            
          <div class="online" :data-online="s.online"></div>
          <span class="address">{{ s.pre }}{{ s.adress }}:{{ s.port }}</span>
          <span class="ping" :data-alert="s.alert"><span v-if="s.ping">{{ s.ping }}ms</span></span>
          </div>
          
          <v-chart class="graph" style="height: 60px" :option="graphSmall(s.pingHistory)" />
        </div>
  
<!--        <v-chart :option="chartData" />-->
<!--        <v-chart :option="test" />-->
      </div>
    </div>
    
    <div class="war_alert" v-if="war_alert">War room activated</div>
    <div class="cadre" :data-alert="war_alert >= 1"></div>
  </div>
</template>

<script>
export default {
  
  name: 'App',
  data() {
    return {
      servers: null,
      war_alert: false
    }
  },
  components: {
    
  },
  created() {
    this.sync()
    let self = this
    setInterval(() => {
      self.sync()
    }, 1000)
  },
  methods: {
    sync() {
      // this.$http.get('http://127.0.0.1:3000/etat').then((data) => {
      this.$http.get('https://api.pleitek.com/etat').then((data) => {
        this.servers = data.data.servers
        this.war_alert = data.data.war_alert
      })
    },
    graph(data) {
      console.log(data)
      return {
        grid: {left: 0, top: 10, bottom: 0, right: 0, height: 120, containLabel: true },
        xAxis: { type: 'category', show: true, boundaryGap: false  },
        yAxis: {type: 'value', show: true, min: 0, max: 200, splitNumber: 3, axisLine : { show: false }, splitLine: { lineStyle: { opacity: .1 } } }, 
        series: [{
          animation: false,
          data: data, type: 'line', showSymbol: false, lineStyle: { width: 1.5 }, itemStyle: {color: '#ffc107'}, areaStyle: {opacity: 0}
        }]
      }
    },
    graphSmall(data) {
      console.log(data)
      return {
        grid: {left: 0, top: -20, bottom: 0, right: 0, height: 80, containLabel: false },
        xAxis: { type: 'category', show: true, axisLine : { show: false }, axisTick : { show: false }, boundaryGap: false },
        yAxis: {type: 'value', show: false, min: 0, max: 200, splitNumber: 4, splitLine: { lineStyle: { opacity: .1 } } }, 
        series: [{
          animation: false,
          data: data, type: 'line', showSymbol: false, lineStyle: {width: 1}, itemStyle: {color: '#ffc107'}, areaStyle: {opacity: .08}
        }]
      }
    }
  }
}
</script>

<style>
html, body { margin: 0; padding: 0; background: #161719; color: #d8d9da;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}

*, :after, :before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}


h1 { margin: 0; padding: 0; padding-bottom: 2rem; }
:root {
  --green: #31a72c;
  --orange: #d67527;
  --red: #e03434;
}

.app { padding: 2rem 3rem; }

.bloc { background: #212124; padding: 1rem 1.5rem 1.5rem 1.5rem; margin-right: .6rem; box-shadow: 1px 1px 2px #181818; width: calc(100% / 4); }

.servers { border: 4px solid transparent }
.servers[data-online="0"] { border: 4px solid var(--red); background: #000; }
.servers .title { font-weight: 700; font-size: 1.5rem; padding-bottom: 1.5rem; text-transform: uppercase; display: flex; align-items: center; justify-content: space-between; }
.servers .title span { text-transform: none; color: var(--green) }

.servers .elem { display: flex; align-items: center; justify-content: space-between; background: #2b2b2e; margin-bottom: .5rem; 
  border-radius: 4px; flex-direction: column; padding: .4rem 0 0 0; overflow: hidden }
.servers .elem .line { display: flex; align-items: center; justify-content: space-between; width: 100%; padding: 0 1rem 0 .4rem }
.servers .elem[data-online=true] { background: #1b6018 }
.servers .elem .address { width: calc(100% - 86px); }
.servers .elem .ping { width: 60px; font-weight: 700; opacity: 1; padding-left: 1rem; color: var(--green) }
.servers [data-alert=true] { color: var(--red) !important }

.servers .elem .online { width: 36px; height: 36px; border-radius: 4px; background: var(--red); margin-right: 1rem; flex: 0 0 auto; }
.servers .elem .online[data-online=true] { background: var(--green) }
.serversBloc { display: flex; }

.war_alert { position: fixed; top: 0; left: 50%; transform: translateX(-50%); background: var(--red); padding: .5rem 2rem; font-size: 2rem; font-weight: 700; text-transform: uppercase;
  animation: alert 1s infinite ease-out;
}

.cadre { position: fixed; top: 0; right: 0; bottom: 0; left: 0; border: 4px solid transparent }
.cadre[data-alert=true] { border-color: var(--red) }

@keyframes alert {
  0% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 0; }
  100% { opacity: 1; }
}

</style>
