import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import axios from 'axios'

Vue.prototype.$http = axios


import ECharts from 'vue-echarts'
// import ECharts modules manually to reduce bundle size
import "echarts";
// import 'echarts/lib/component/c'
// import 'echarts/lib/chart/graph'
Vue.component('v-chart', ECharts)

new Vue({
  render: h => h(App),
}).$mount('#app')
